// bootstrap
var $ = require('jquery');



import './styles/error/error.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/carousel';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';